import {ElNotification,ElMessage} from'element-plus'
import axios from 'axios'
import Qs from 'qs'
const service = axios.create({
  baseURL: '',
  timeout: 50000
})
service.interceptors.request.use(
  config => {
  if (window.localStorage.getItem('userId')) {
    config.data.userId = window.localStorage.getItem('userId')
  }
  // if (window.localStorage.getItem('shopId')) {
  if (window.localStorage.getItem('shopId') && !config.data.shopId) {
    config.data.shopId = window.localStorage.getItem('shopId')
  }
  if (window.localStorage.getItem('token')) {
    config.data.token = window.localStorage.getItem('token')
    }
  config.data.tokenId = window.localStorage.getItem('shopId')
  config.data.platform = 'web'
    if (config.headers['Content-Type'] == null) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;'
    }
    if (config.headers['Content-Type'].indexOf('application/x-www-form-urlencoded') !== -1) {
      config.transformRequest = [function (data) {
        data = Qs.stringify(data)
        return data
      }]
    }
    return config  
  }
,error => {
  return Promise.reject(error)
})
let modeStatus = true
let item = null;
let modeStatusFun = ()=>{
  modeStatus = false
  if (item !== null) {
    clearTimeout(item)
  }
  item = setTimeout(() => {
    modeStatus = true
    clearTimeout(item)
  },2000)
}
service.interceptors.response.use((response) => {
  const res = response.data
  if (res.code !==200 && res.code !==1021 && res.code !== 240 && modeStatus) {
    modeStatusFun()
    ElNotification({
      title: '错误',
      message: res.message,
      type: 'error',
    })
  } else if (res.code ===1021 && modeStatus) {
    modeStatusFun()
    ElMessage(res.message)
  } else if (res.code === 240) {
    modeStatusFun()
    ElNotification({
      title: '无数据',
      message: res.message,
      type: 'error',
    })
    return {data:[]}
  }
  if(res.code === 200)
  return res 
}, error => {
  ElNotification({
    message: error.response.data,
    type: '请求失败',
    duration: 5 * 1000,
    showClose: true
  })
  return Promise.reject(error)
})


export default service