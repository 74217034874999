import { createStore } from 'vuex'

export default createStore({
  state: {
    reqData: {
      shopName: '全部店铺',
      dateType: '日',
      reqTimeMin:(new Date().getFullYear()) + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate()),
      reqTimeMax:(new Date().getFullYear()) + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate()),
    },
    classDate: [],
    classSub : -1,
    dataDate: [],
    dataSub :-1,
    weekDate: [],
    weekSub: -1,
    monthDate: [],
    monthSub : -1,
    seaDate: [],
    seaSub: -1,
    yearDate: [],
    yearSub:1,
  },
  mutations: {
    changeReqData(state, paload) {
      state.reqData = paload
    },
    setClass(state, paload) {
      state.classDate = paload
    },
    setData(state, paload) {
      state.dataDate = paload
    },
    setWeek(state, paload) {
      state.weekDate = paload
    },
    setMonth(state, paload) {
      state.monthDate = paload
    },
    setSea(state, paload) {
      state.seaDate = paload
    },
    setYear(state, paload) {
      state.yearDate = paload
    },
    //下标
    setClassSub(state, paload) {
      state.classSub = paload
    },
    setDataSub(state, paload) {
      state.dataSub = paload
    },
    setDaSuby(state, paload) {
      state.daySub = paload
    },
    setMonthSub(state, paload) {
      state.monthSub = paload
    },
    setSeaSub(state, paload) {
      state.seaSub = paload
    },
    setYearSub(state, paload) {
      state.yearSub = paload
    }
  },
  actions: {
  },
  modules: {
  }
})
