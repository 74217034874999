<template>
  <el-config-provider :locale="zhCn">
    <Vab-App />
    <router-view></router-view>
  </el-config-provider>
</template>
<script setup>
  import zhCn from 'element-plus/lib/locale/lang/zh-cn'
  import { onMounted } from 'vue'
  onMounted(() => {
      // 这句话才是重点
      document.body.style.setProperty('--el-color-primary','#55BFF0');
  })
</script>

<style>
*{
  padding: 0px;
  margin: 0px;
  white-space: nowrap;
  font-family: 'Arial Normal', 'Arial';
}
html,body,#app{
  height: 100%;
}
a{
  text-decoration: none;
}
li{
  list-style: none;
}
</style>
