import service from "@/util/request.js"
// 2.开始封装请求
const api = '/api/'// 测试地址
export function getData(url, params) {
    return new Promise((resolve,reject)=>{
        service.request({
            // 原来的axios怎么写 现在就怎么写
            url:api + url,
            method:"GET",
            params
        }).then((ok)=>{
            resolve(ok)
        }).catch((err)=>{
            reject(err)
        })
    })
}
export function getLink(url) {
    return new Promise((resolve,reject)=>{
        service.request({
            // 原来的axios怎么写 现在就怎么写
            url:api  + url,
            method:"GET",
        }).then((ok)=>{
            resolve(ok)
        }).catch((err)=>{
            reject(err)
        })
    })
 }
export function postData(url, data) {
    return new Promise((resolve,reject)=>{
        service.request({
            // 原来的axios怎么写 现在就怎么写
            url:api + url,
            method:"POST",
            data
        }).then((ok)=>{
            resolve(ok)
        }).catch((err)=>{
            reject(err)
        })
    })
 }