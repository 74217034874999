<template>
<div>
  <br>
  <br>
  <br>
    <div id="box" >
      <!-- <el-button size="medium" @click="print1()" type="primary">打印</el-button> -->
      <el-button size="medium" :disabled="btnStatus" @click="funExport()" type="warning">导出</el-button>
    </div>
</div>
</template>
<script>
import { ref } from 'vue'
import {useRoute} from'vue-router'
export default {
props:['rul','add'],
setup(props){
  let btnStatus = ref(false)
  let route = useRoute()
  let baseURL = 'http://'+location.host+'/api/'
  if(window.location.href.indexOf("https") > -1){
    baseURL = 'https://'+location.host+'/api/'
  }
  const funExport = ()=>{
    btnStatus.value = true
    console.log(props)
    let query = ''
    let newDate = new Date()
    // 有时间
    if(route.query.dateMin && route.query.dateMin.length>3){
      if(route.query.dateType === '班次'){
        query+='dutyIds='+route.query.dateMin
      }else{
        let date = new Date(route.query.dateMin)
        query += 'from='+date.getFullYear()+
        '-'+((date.getMonth()+1)>9?(date.getMonth()+1):('0'+(date.getMonth()+1)))+
        '-'+ ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
        if(route.query.dateMax && route.query.dateMax.length>3 && route.query.dateType !== '年'){
          let date = new Date(route.query.dateMax)
          query += '&to='+date.getFullYear()+
          '-'+((date.getMonth()+1)>9?(date.getMonth()+1):('0'+(date.getMonth()+1)))+
          '-'+ ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
          console.log(50,query)
        }else if(route.query.dateMax && route.query.dateType === '年'){
          let date = new Date(route.query.dateMax)
          query += '&to='+date.getFullYear() + '-' + '12' + '-' + '31'
        }else{
          query += '&to='+newDate.getFullYear()+
          '-'+((newDate.getMonth()+1)>9?(newDate.getMonth()+1):('0'+(newDate.getMonth()+1)))+
          '-'+ ((newDate.getDate())>9?(newDate.getDate()):('0'+(newDate.getDate())))
        }
      }
    }else{
      query += 'from='+newDate.getFullYear() +
      '-'+((newDate.getMonth()+1)>9?(newDate.getMonth()+1):('0'+(newDate.getMonth()+1)))+
      '-'+ ((newDate.getDate())>9?(newDate.getDate()):('0'+(newDate.getDate())))
      query += '&to='+newDate.getFullYear() +
      '-'+((newDate.getMonth()+1)>9?(newDate.getMonth()+1):('0'+(newDate.getMonth()+1)))+
      '-'+ ((newDate.getDate())>9?(newDate.getDate()):('0'+(newDate.getDate())))
    }
    console.log(58,query)
    query += '&userId=' + window.localStorage.getItem('userId')
    query += '&platform=web'
    query += '&token=' + window.localStorage.getItem('token')
    query += '&tokenId=' + window.localStorage.getItem('shopId')
    if(route.query.shopId && route.query.dateType!= undefined){
      query += '&shopId=' + route.query.shopId
    }else{
      query += '&shopId=' + window.localStorage.getItem('shopId')
    }
    if(props.add && props.add != ''){
      query+=props.add
    }
    let reqRrl = baseURL + props.rul +'?'
    reqRrl = reqRrl + query
    console.log(reqRrl)
    window.location.href = reqRrl
    let item = setTimeout(() => {
      btnStatus.value = false
      clearTimeout(item)
    }, 2000);
  }
  return{
    funExport,
    btnStatus
  }
}
}
</script>
<style scoped>
#box{
  background-color:#D9EFFF;
  display:flex;
  justify-content:end;
  padding:20px 40px;
  margin:0
}
</style>
