<template>
  <div class="login">
    <header style="padding: 40px"><img src="@/assets/icons/icon_1.png" alt=""></header>
    <div ref="loginBox" class="login-box">
      <main ref="main">
        <div class="bgc"></div>
        <div class="logo-login"><img width="120" src="@/assets/icons/icon_login.png" alt=""><div class="admin-text">美加美茶楼财务系统</div></div>
        <div style="padding-bottm:25px"></div>
        <div class="log-box">
          <div style="margin:0px 15px 0px 20px;display:inline-block" ><img width="25" style="vertical-align: bottom;" src="@/assets/icons/phone.png" alt=""></div>
          <input class="log-inp" v-model="phone" type="text" placeholder="手机号" onkeyup="this.value=this.value.replace(/\D/g,'')">
        </div>
        <div class="log-box">
          <div style="margin:0px 15px 0px 20px;display:inline-block" ><img width="25" style="vertical-align: bottom;" src="@/assets/icons/pwd.png" alt=""></div>
          <input class="log-inp" v-model="password" placeholder="密码" type="password">
        </div>
        <div class="login-style" @click="login()">登录</div>
        <div class="login-bottom" style="color:#fff">
          <div><el-switch v-model="loginChe" />&nbsp;记住 &nbsp;&nbsp;|&nbsp;&nbsp;<span style="cursor: pointer;" @click="forget(true)">忘记密码</span></div>
        </div>
        <a class="href" @click="hrefGo()">进入收银端&nbsp;&nbsp;<span style="font-size: 12px;letter-spacing: -9px;">〉〉</span></a>
      </main>
      <main ref="forgetPage" style="height:600px;top: -40px;" class="forget">
        <div class="bgc" style="height:450px;border-radius: 25px;"></div>
        <div class="logo-login"><img width="120" style="top: 70%" src="@/assets/icons/icon_09.png" alt=""></div>
        <div style="padding-bottm:25px"></div>
        <div class="log-box" ref="boxOne">
          <div style="margin:0px 15px 0px 20px;display:inline-block" ><img width="25" style="vertical-align: bottom;" src="@/assets/icons/phone.png" alt=""></div>
          <input class="log-inp"  v-model="phone" type="text" placeholder="手机号" onkeyup="this.value=this.value.replace(/\D/g,'')">
        </div>
        <div class="log-box" ref="boxTwo">
          <div style="margin:0px 15px 0px 20px;display:inline-block" ><img width="25" style="vertical-align: bottom;" src="@/assets/icons/msg.png" alt=""></div>
          <!-- <input class="log-inp" v-if="!msgInpState" :disabled="!msgInpState" style="width: 175px" v-model="phoneMsg" type="text" placeholder="请先获取验证码"> -->
          <input class="log-inp" style="width: 175px" v-model="phoneMsg" type="text" placeholder="验证码">
          <button @click="msgButtonFun()" class="msg-Button" :disabled="!phoneMsgState">{{phoneMsgState?'':'('}}{{phoneMsgNumber}}{{phoneMsgState?'':')获取中'}}</button>
        </div>
        <div class="log-box" ref="boxThree">
          <div style="margin:0px 15px 0px 20px;display:inline-block" ><img width="25" style="vertical-align: bottom;" src="@/assets/icons/pwd.png" alt=""></div>
          <input class="log-inp" ref="pwdOne" v-model="changePwd" placeholder="输入新密码" type="password">
          <img width="25" @mouseover="lookOver(1)" @mouseout="versa(1)" src="@/assets/icons/chakan.png" alt="">
        </div>
        <div class="log-box" ref="boxFour">
          <div style="margin:0px 15px 0px 20px;display:inline-block" ><img width="25" style="vertical-align: bottom;" src="@/assets/icons/pwd.png" alt=""></div>
          <input class="log-inp" ref="pwdTwo" v-model="changePwdTwo" placeholder="再次输入密码"  type="password">
          <img width="25" @mouseover="lookOver(2)" @mouseout="versa(2)" src="@/assets/icons/chakan.png" alt="">
        </div>
        <div class="login-style" @click="changeLoginPwd()">确认</div>
        <div class="login-bottom" style="color:#fff;display: flex;justify-content: flex-end;">
          <div style="float:right"><span style="cursor: pointer;" @click="forget(false)">返回密码登录&nbsp;&nbsp;&nbsp;</span></div>
        </div>
        <a class="href" @click="hrefGo()">进入收银端&nbsp;&nbsp;<span style="font-size: 12px;letter-spacing: -9px;">〉〉</span></a>
      </main>
    </div>
  </div>
</template>
<script>
import {ref} from'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import {postData} from'@a'
import md5 from 'js-md5'
export default {
  setup(){
    const router = useRouter()
    let loginChe = ref(false)
    let phoneMsg = ref('')
    let phoneMsgState = ref(true)
    let phoneMsgNumber = ref('获取验证码')
    let changePwd = ref('')
    let changePwdTwo = ref('')
    window.sessionStorage.setItem("logStatue",false)
    let phone = ref("")
    let password = ref("")
    let phoneReg = /^[0-9]{11}$/;
    if(window.localStorage.getItem('loginChe') === 'true'){
      loginChe.value = true
      phone.value = window.localStorage.getItem('phone')
                      // 解密
      password.value = window.localStorage.getItem('password')
      // password.value = window.atob(window.localStorage.getItem('password'))
    }
    const logging = function (res){     
      window.localStorage.setItem('userId',res.uid)
      window.localStorage.setItem('shopId',res.shopId)
      window.localStorage.setItem('shopIds',res.shopIds)
      window.localStorage.setItem('token',res.token)
      window.sessionStorage.setItem("logStatue",true)
      if(loginChe.value){                   // 加密
        window.localStorage.setItem('password', password.value)
        // window.localStorage.setItem('password', window.btoa(password.value))
        window.localStorage.setItem("loginChe",true)
      }else{
        window.localStorage.setItem("loginChe",false)
        window.localStorage.removeItem('password')
        window.localStorage.removeItem('phone')
      }
      window.localStorage.setItem('phone',phone.value)
      window.localStorage.setItem('mobile',phone.value)
      router.replace({ name: 'home'})
    }
    const open1 = (res) => {
      window.sessionStorage.setItem('hisdataYear',res.hisdataYear)
      ElMessage({
        message: '登录成功',
        type: 'success',
      })
      window.localStorage.setItem('shopName','全部店铺')
      logging(res)
    }
    const open4 = (i) => {
      if(i === 1){
        ElMessage.error('请输入正确的手机号！')
      }
      if(i === 2){
        ElMessage.error('请输入密码！')
      }
    }
    const hrefGo = () => {
      window.location.href='https://chalou.mding.org/#/access/signin'
    }
    const login = async()=>{
      if(!phoneReg.test(phone.value)){
         open4(1)
         return
      }
      if(password.value.length<1){
         open4(2)
         return
      }
      try {
        let param = {
          mobile:phone.value,
          pwd:md5(password.value).toUpperCase(),
        }
        let res = await(postData("login/bossLogin.json",param))
        open1(res.data)
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    }
    const main = ref({})
    const forgetPage = ref({})
    const loginBox = ref({})
    const forget = (state)=>{
      console.log(forget.value)
      loginBox.value.style.overflow= 'initial'
      if(state){
        main.value.style.left = "-200px"
        main.value.style.opacity = '0'
        forgetPage.value.style.opacity = '1'
        forgetPage.value.style.left = "50%"
        // forgetPage.value.style.display = 'block'
        let time = setTimeout(() => {
          loginBox.value.style.overflow= 'hidden'
          clearTimeout(time)
        }, 1000);
      }else{
        main.value.style.left = "50%"
        main.value.style.opacity = '1'
        // main.value.style.display = 'block'
        forgetPage.value.style.left = "800px"
        forgetPage.value.style.opacity = '0'
        let time = setTimeout(() => {
          // forgetPage.value.style.display = 'none'
          loginBox.value.style.overflow= 'hidden'
          clearTimeout(time)
        }, 1000);
      }
    }
    let time = {}
    // let msgInpState = ref(false)
    const msgButtonFun = async()=>{
      if(!phoneReg.test(phone.value)){
         open4(1)
         return
      }
      phoneMsgState.value = false
      phoneMsgNumber.value = 60
      time = setInterval(()=>{
        phoneMsgNumber.value -= 1
        if(phoneMsgNumber.value === 0){
          phoneMsgNumber.value = '获取验证码'
          clearInterval(time)
          phoneMsgState.value = true
        }
      },1000)
      try {
        await postData('login/reset_pwd_get_code.json',{mobile:phone.value})
      } catch (err) {
        console.log(err)
      }
    }
    let boxOne = ref({})
    let boxTwo = ref({})
    let boxThree = ref({})
    let boxFour = ref({})
    const bgcChange = (ele)=>{
      ele.value.style.borderColor = 'red'
      let time = setTimeout(()=>{
        ele.value.style.borderColor = '#fff'
        clearTimeout(time)
      },200)
    }
    const changeLoginPwd = async()=>{
      if(!phoneReg.test(phone.value)){
        open4(1)
        bgcChange(boxOne)
        return
      }
      if(phoneMsg.value === ''){
         ElMessage.error('请输入验证码！')
         bgcChange(boxTwo)
         return
      }
      if(changePwd.value === ''){
         ElMessage.error('请输入密码！')
         bgcChange(boxThree)
         return
      }
      if(changePwdTwo.value === ''){
         ElMessage.error('请输入再次输入密码！')
         bgcChange(boxFour)
         return
      }
      if(changePwdTwo.value !== changePwd.value){
         ElMessage.error('两次输入密码不一致！')
         bgcChange(boxThree)
         bgcChange(boxFour)
         return
      }
    try {
      let param  = {
        mobile:phone.value ,
        pwd:md5(changePwd.value).toUpperCase(),
        code:phoneMsg.value
      }
      let res = await postData('login/reset_pwd.json',param)
      if(res.code === 200){
        phoneMsg.value = ''
        changePwd.value = ''
        changePwdTwo.value = ''
        ElMessage({
          message: '修改成功！',
          type: 'success',
        })
        forget(false)
      }
    } catch (err) {
      console.log(err)
    }

    }
    let pwdOne = ref({})
    let pwdTwo = ref({})
    const lookOver = (i)=>{
      if(i===1){
        pwdOne.value.setAttribute('type','text')
      }else{
        pwdTwo.value.setAttribute('type','text')
      }
    }
    const versa = (i)=>{
      if(i===1){
        pwdOne.value.setAttribute('type','password')
      }else{
        pwdTwo.value.setAttribute('type','password')
      }
    }
  return {
    logging,
    phone,
    password,
    loginChe,
    login,
    hrefGo,
    forget,
    main,
    forgetPage,
    phoneMsg,
    phoneMsgState,
    changePwd,
    changePwdTwo,
    msgButtonFun,
    phoneMsgNumber,
    changeLoginPwd,
    lookOver,
    pwdOne,
    pwdTwo,
    versa,
    // msgInpState,
    boxOne,
    boxTwo,
    boxThree,
    boxFour,
    loginBox,
  }
  }
}
</script>

<style scoped>
.login{
  background-color: #287AB4;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.login-box{
  width:600px;
  width:600px;
  height: 550px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-70%);
  overflow: hidden;
  /* border: 1px solid red; */
}
.forget{
  width:400px;
  height: 532px;
  position: absolute;
    /* top: 428px; */
  left: 800px;
  transform: translateX(-50%);
  overflow: hidden;
  /* background-color: #1E6DA6; */
  transition: all 1s;
  /* opacity: 1; */
  opacity: 0;
  /* display: none; */
}
main{
  width:400px;
  height: 467px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  padding: 0 20px 0px 20px;
  box-sizing: border-box;
  transition: all 1s;
  opacity: 1;
}
.bgc{
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-color: #1E6DA6;
  width: 100%;
  height: 415px;
  z-index: -1;
  margin: 0;
  padding: 0;
}
main>div{
  margin-bottom: 25px;
}
.logo-login{
  position: relative;
  height: 200px;
}
.logo-login>img{
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translateX(-50%) translateY(-50%);
}
.admin-text{
    position: absolute;
    bottom: -10px;
    color: #fff;
    background-color: #1E6DA6;
    width: 100%;
    height: 50px;
    font-size: 25px;
    text-align: center;
    left: 0;
    line-height: 50px;
}
.login-bottom{
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.login-style{
  font-size:17px;
  background-color:#fff;
  color:#58666E;
  border-radius: 25px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.33;
  padding: 10px 12px;
  text-align: center;
    cursor: pointer;
}
.log-box{
  border-radius: 25px;
  border: 1px solid #fff;
  /* padding-left: 50px; */
}
.log-inp{
  box-sizing: border-box;
  padding: 6px 12px 6px 0;
  outline:none;
  font-size: 20px;
  border: none;
  /* border-radius: 25px; */
  /* width: 100%; */
  background-color: transparent;
  color: #fff;
  height: 46px;
  line-height: 46px;
}
.log-inp::-webkit-input-placeholder { /* WebKit browsers */
color: #8FB7D3;
}
.log-inp:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
color: #8FB7D3;
}
.log-inp::-moz-placeholder { /* Mozilla Firefox 19+ */
color: #8FB7D3;
}
.log-inp:-ms-input-placeholder { /* Internet Explorer 10+ */
color: #8FB7D3;
}
.href{
    margin: 0px auto;
    font-size: 16px;
    text-align: center;
    line-height: 65px;
    width: 400px;
    color: #D9FDFF;
    height: 65px;
    background-color: #17598B;
    display: block;
    border: none;
    outline: none;
    position: absolute;
    bottom: -65px;
    left: 0px;
    cursor: pointer;
}
.msg-Button{
  color: #ffffff;
  width: 120px;
  height: 40px;
  background: #5AD71B;
  font-size: 16px;
  border: 0;
  border-radius: 23px;
  cursor: pointer;
}
/* .msgState{
  color: #ffffff;
  width: 120px;
  height: 40px;
  background: #2f700f;
  font-size: 16px;
  border: 0;
  border-radius: 23px;
  cursor: pointer;
} */
.forget>div{
  margin-bottom: 15px;
  transition: all 0.2s;
}
</style>