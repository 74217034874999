import { createRouter, createWebHashHistory } from 'vue-router'
import log from '@v/log/log.vue'
const routes = [
  {
    path: '/log',
    name: 'log',
    component: log
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@v/home/home.vue'),
    redirect: { name: 'report' } ,
    children:[
      {
        path: 'report',
        name: 'report',
        component: ()=> import('@v/home/homeList/report.vue'),
        redirect: { name: 'togetherShop' } ,
        children:[
          {
            name: 'aloneShop',
            path: 'aloneShop',
            component: ()=> import('@v/home/homeList/report/aloneShop.vue')
          },
          {
            name: 'togetherShop',
            path: 'togetherShop',
            component: ()=> import('@v/home/homeList/report/togetherShop.vue')
          },
          {
            path: 'deductGoods',
            name: 'deductGoods',
            component: ()=> import('@v/home/homeList/report/deductGoods.vue')
          },
          {
            path: 'deductStaff',
            name: 'deductStaff',
            component: ()=> import('@v/home/homeList/report/deductStaff.vue')
          },
        ]  
      },
      {
        path: 'system',
        name: 'system',
        component: ()=> import('@v/home/homeList/system.vue')
      },
      {
        path: 'vipForm',
        name: 'vipForm',
        component: ()=> import('@v/home/homeList/vipForm.vue'),
        redirect: { name: 'all' } ,
        children:[
          {
            name: 'all',
            path: 'all',
            component: ()=> import('@v/home/homeList/vipList/all.vue')
          },
          {
            name: 'integral',
            path: 'integral',
            component: ()=> import('@v/home/homeList/vipList/integral.vue')
          },          {
            name: 'remainingSum',
            path: 'remainingSum',
            component: ()=> import('@v/home/homeList/vipList/remainingSum.vue')
          },
          {
            path: 'vipDetailPage',
            name: 'vipDetailPage',
            component: ()=> import('@v/home/homeList//vipList/vipDetailPage.vue')
          }
        ]
      },
      {
        path: 'moreTaking',
        name: 'moreTaking',
        component: ()=> import('@v/home/homeList/details/moreTaking.vue')
      },
      {
        path: 'moreIncome',
        name: 'moreIncome',
        component: ()=> import('@v/home/homeList/details/moreIncome.vue')
      },
      {
        path: 'moreVipDetails',
        name: 'moreVipDetails',
        component: ()=> import('@v/home/homeList/details/moreVipDetails.vue')
      },
      {
        path: 'taking',
        name: 'taking',
        component: ()=> import('@v/home/homeList/details/taking.vue')
      },
      {
        path: 'income',
        name: 'income',
        component: ()=> import('@v/home/homeList/details/income.vue')
      },
      {
        path: 'vipDetails',
        name: 'vipDetails',
        component: ()=> import('@v/home/homeList/details/vipDetails.vue')
      },
      {
        path: 'discounts',
        name: 'discounts',
        component: ()=> import('@v/home/homeList/details/discounts.vue')
      },
      {
        path: 'repayment',
        name: 'repayment',
        component: ()=> import('@v/home/homeList/details/repayment.vue')
      },


      {
        path: 'oneself',
        name: 'oneself',
        component: ()=> import('@v/home/homeList/oneself.vue')
      },
    ]
  },
  {
    path:'/',
    redirect:'/log'
  },
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: () => import( '@v/404/404.vue')
  },
]
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to) => {
  if(window.sessionStorage.getItem("logStatue") === 'true' || to.path === "/log"){
    return true
  }
  router.replace({path: '/log'})
})
export default router
