<template>
<div>
  <br>
  <br>
  <br>
    <div id="box" >
      <!-- <el-button size="medium" @click="print1()" type="primary">打印</el-button> -->
      <el-button size="medium" @click="funExport()" type="warning">导出</el-button>
    </div>
</div>
</template>
<script>
import {useRoute} from'vue-router'
export default {
props:['rul','add'],
setup(props){
  let baseURL = 'http://'+location.host+'/api/'
  if(window.location.href.indexOf("https") > -1){
    baseURL = 'https://'+location.host+'/api/'
  }
  let route = useRoute()
  // if(!props.add) props.add = ''
  console.log(props.add)
  const funExport = ()=>{
    console.log(props.add)
    let query = ''
    query += 'userId=' + window.localStorage.getItem('userId')
    query += '&platform=web'
    query += '&token=' + window.localStorage.getItem('token')
    query += '&tokenId=' + window.localStorage.getItem('shopId')
    if(route.query.shopId){
      query += '&chainShopId=' + route.query.shopId
    }
      query += '&shopId=' + window.localStorage.getItem('shopId')
    if(props.add && props.add != ''){
      query+=props.add
    }
    let reqRrl = baseURL + props.rul +'?'
    reqRrl = reqRrl + query
    console.log(reqRrl)
    window.location.href = reqRrl
  }
  return{
    funExport,
  }
}
}
</script>
<style scoped>
#box{
  background-color:#D9EFFF;
  display:flex;
  justify-content:end;
  padding:20px 40px;
  margin:0
}
</style>
