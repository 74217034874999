import { createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import printJS from 'print-js'
import Print from '@c/util/print.vue'
import PrintNoFrom from '@c/util/printNoFrom.vue'
require("@m")

const app = createApp(App)
installElementPlus(app)
app.use(store).use(router).mount('#app')
window.printJS = printJS
// 打印组件
app.component('Print',Print)
app.component('PrintNoFrom', PrintNoFrom)
export default app